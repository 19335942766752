import { Box, Container, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { useConfig } from '../../ConfigProvider';
import { useAuth } from '../../AuthProvider';
import BackgroundMedia from './BackgroundMedia';

interface CentreContainerProps {
  children: ReactNode;
  hasLogo?: boolean;
  containerWidth?: 'sm' | 'md' | 'lg' | 'xl';
}

const CentreContainer: React.FC<CentreContainerProps> = ({
  children,
  hasLogo,
  containerWidth = 'sm',
}) => {
  const theme = useTheme();
  const config = useConfig();
  const auth = useAuth();

  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '100vh',
        padding: '2rem',
        py: auth.user ? '8rem' : 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
          padding: '0',
        },
      }}
    >
      <BackgroundMedia src={config?.theme?.background} />
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          backgroundColor: 'white',
          padding: '0 !important',
          borderRadius: '0.5rem',
          [theme.breakpoints.down('sm')]: {
            padding: '1rem',
            px: 0,
            height: '100vh',
          },
        }}
        maxWidth={containerWidth || 'md'}
      >
        {hasLogo ? (
          <Box
            sx={{
              border: '1px solid lightgrey',
              borderRadius: '10%',
              backgroundColor: 'white',
              width: '10rem',
              height: '10rem',
              display: 'flex',
              justifyContent: 'center',
              boxSizing: 'border-box',
              mb: '1rem',
              mt: '2.5rem',
              [theme.breakpoints.down('xl')]: {
                width: '7rem',
                height: '7rem',
              },
              [theme.breakpoints.down('md')]: {
                width: '6rem',
                height: '6rem',
              },
            }}
          >
            <img
              src={config?.theme?.logo}
              alt='Logo'
              style={{
                width: '100%',
                height: 'auto',
                objectFit: 'contain',
                borderRadius: '10%',
                padding: '1rem',
              }}
            />
          </Box>
        ) : null}
        {children}
      </Container>
    </Box>
  );
};

export default CentreContainer;
