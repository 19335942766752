import { Box } from '@mui/material';
import React from 'react';

interface BackgroundMediaProps {
  src?: string;
  overlay?: boolean;
}

const VIDEO_EXTENSIONS = ['.mp4', '.webm', '.ogg'];

export const BackgroundMedia: React.FC<BackgroundMediaProps> = ({ src, overlay = true }) => {
  if (!src) return null;

  const isVideo = VIDEO_EXTENSIONS.some((ext) => src.toLowerCase().endsWith(ext));

  if (isVideo) {
    return (
      <>
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -2,
            '&::after': overlay
              ? {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  background: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6))',
                }
              : {},
          }}
        >
          <video
            autoPlay
            muted
            loop
            playsInline
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <source src={src} type={`video/${src.split('.').pop()}`} />
          </video>
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: overlay
          ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(${src})`
          : `url(${src})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        zIndex: -1,
      }}
    />
  );
};

export default BackgroundMedia;
